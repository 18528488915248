@tailwind base;
@tailwind components;

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
  .no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  overflow: hidden;
}

body {
  height: 100%;
  width: 100vw;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.persona-btn {
  @apply bg-persona-white font-header text-persona-textDark text-opacity-80 p-5 rounded-xl shadow-md
}

.persona-btn-hover {
  @apply transform transition duration-200 hover:scale-105 hover:-translate-y-0.5 hover:shadow-agressive
}

@tailwind utilities;